import { Alert, Button } from 'antd'
import { useIntl } from 'react-intl'

export function InformationBanner() {
  const intl = useIntl()

  return (
    <>
      <Alert
        message={
          <div>
            <strong>{intl.formatMessage({ id: 'app.information-banner.flexportal-is-here' })}</strong>
            {intl.formatMessage({ id: 'app.information-banner.flexportal-is-here-description' })}
          </div>
        }
        type="warning"
        showIcon
        action={
          <Button size="middle" type="primary" href="https://flexportal.sympower.net">
            {intl.formatMessage({ id: 'app.information-banner.try-new-flexportal' })}
          </Button>
        }
      />
    </>
  )
}
